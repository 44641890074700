.hsc {
  &-logo {
    text-align: right;
    display: inline-block;
    line-height: 0.7;
    color: #925c79;
    font-size: 6rem;
    margin: 0;
    span {
      display: block;
    }
  }

  &-under {
    font-family: "Atkinson";
    display: block;
    color: #925c79;
    text-align: right;
  }
}
