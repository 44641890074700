@font-face {
    font-family: "Atkinson";
    src: local("Atkinson"), 
    url('../../assets/fonts/Atkinson-Hyperlegible-Bold-102a.woff2') format('woff2'),
    url('../../assets/fonts/Atkinson-Hyperlegible-Bold-102.ttf') format('ttf');
    font-weight: 700;
}

h1 {
    font-family: "Atkinson";
    letter-spacing: -2px;
    text-transform: uppercase;
    transform: scale(.96, .95);
}